import React, { useEffect, useState } from "react";
import {
  AppContainer,
  ContainerPubs,
  ContainersNewsPage,
  SectionTitle,
} from "../../styles/containers";
import HeaderApp from "../../containers/header";
import { BodyComponent, TopContent } from "../../styles/home";
import { GetEventsDetail, GetPubs } from "../../configuration/api";
import { useNavigate, useParams } from "react-router";
import {
  WindowsSizeBreakpoints,
  isFieldWithValue,
  isMobileOrSmallScreen,
} from "../../utils";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { Editor, EditorState, convertFromRaw } from "draft-js";
import SectionPartners from "../../components/section-partners";
import SectionNewsLetter from "../../components/section-newsletter";
import FooterApp from "../../containers/footer";
import { useWindowSize } from "../../utils/useWindowSize";
import CardPub from "../../components/cards/card-pub";

export default function NewsDetails() {
  const { code } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [contentState, setContentState] = useState(null);
  const [info, setInfo] = useState(null);
  const [pubs, setPubs] = useState([]);
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const { width } = useWindowSize();

  useEffect(() => {
    getPubs();
  }, []);

  useEffect(() => {
    if (!isFieldWithValue(code)) {
      navigate(-1);
    } else {
      getNewsDetail();
    }
  }, []);

  useEffect(() => {
    if (contentState) {
      setIsLoading(false);
    }
  }, [contentState]);

  const getPubs = async () => {
    try {
      const payload = {
        page: 1,
        limit: 50,
      };
      const response = await GetPubs(payload);
      const { success, data, error } = response.data;
      if (success) {
        setPubs(data.pubs || []);
      }
    } catch (error) {
      console.log({ error });
    }
  };

  const getNewsDetail = async () => {
    try {
      const response = await GetEventsDetail(code);
      const { success, data } = response.data;
      if (success) {
        const value = JSON.parse(data.description[0]);
        let html = draftToHtml(value);
        html = html.replace(/<img/g, '<img style="width: 100%;"');
        setContentState(html);
        setInfo(data);
      } else navigate(-1);
    } catch (error) {
      console.log({ error });
      //   navigate(-1);
    }
  };

  return (
    <AppContainer>
      <HeaderApp />
      <BodyComponent>
        <TopContent
          style={{
            minHeight: "14vh",
            backgroundColor: "#FFF",
            display: "block",
            overflow: "hidden",
          }}
        >
          {isLoading ? (
            <div
              className="in-container-loader"
              style={{
                marginTop: "10vh",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <div className="spinner-loader"></div>
            </div>
          ) : (
            <>
              <ContainersNewsPage
                style={{
                  ...(isMobileDevice ||
                  Boolean(width <= WindowsSizeBreakpoints.medium)
                    ? {
                        display: "contents",
                      }
                    : {
                        display: "grid",
                      }),
                }}
              >
                <div>
                  <SectionTitle
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: "25px",
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <hr width="100%" size="2" color="#EBEBEB" />
                    <h4
                      style={{
                        marginTop: "0.5em",
                        marginBottom: "0em",
                        color: "#000",
                        textAlign: "center",
                      }}
                    >
                      {info?.title}
                    </h4>
                    <hr width="100%" size="2" color="#EBEBEB" />
                    <img
                      src={info?.image}
                      alt="audit"
                      style={{
                        width: "65%",
                        border: "2px solid white",
                        marginTop: "15px",
                      }}
                    />
                  </SectionTitle>
                  <div dangerouslySetInnerHTML={{ __html: contentState }} />
                </div>
                {!isMobileDevice && width > WindowsSizeBreakpoints.medium && (
                  <ContainerPubs>
                    <small className="pubs">Publicités</small>
                    {pubs?.map((data, key) => (
                      <CardPub urlImg={data.image} name={data.name} key={key} />
                    ))}
                  </ContainerPubs>
                )}
              </ContainersNewsPage>
            </>
          )}
        </TopContent>
        <SectionNewsLetter />
        <SectionPartners padding="0px" margin="0px" width="100%" />
      </BodyComponent>
      <FooterApp />
    </AppContainer>
  );
}
