import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContainer } from "../../../styles/containers";
import HeaderApp from "../../../containers/header";
import { BodyComponent } from "../../../styles/home";
import CustomHeaderTitle from "../../../components/extras/custom-header-title";
import SectionPartners from "../../../components/section-partners";
import SectionNewsLetter from "../../../components/section-newsletter";
import FooterApp from "../../../containers/footer";
import { GetRecapData } from "../../../configuration/api";
import SectionAwareNoticePage from "../../../components/section-aware-notice";
import { Helmet } from "react-helmet";

export default function AwareNoticePage() {
  const { type } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [recapData, setRecapData] = useState(null);
  const [defaultSelectedType, setDefaultSelectedType] = useState();

  useEffect(() => {
    getRecapData();
  }, []);

  useEffect(() => {
    if (recapData) setIsLoading(false);
  }, [recapData]);

  const getRecapData = async () => {
    try {
      setIsLoading(true);
      const response = await GetRecapData();
      const { success, data, error } = response.data;
      if (success) {
        setRecapData(data);
      }
    } catch (error) {
      console.log({ error });
      setRecapData({});
    }
  };
  return (
    <>
      <Helmet>
        <title>Avis d'Attribution - Provisoires et Définitives</title>
        <meta
          name="description"
          content="Consultez les avis d'attribution provisoires et définitifs pour connaître les résultats des appels d'offre."
        />
        <meta
          property="og:title"
          content="Avis d'Attribution - Résultats des Appels d'Offre"
        />
        <meta
          property="og:description"
          content="Découvrez les avis d'attribution provisoires et définitifs pour les appels d'offre récents. Suivez les résultats et les entreprises sélectionnées."
        />
        <meta
          property="og:image"
          content="https://res.cloudinary.com/faceshop/image/upload/c_fit,h_150,w_150/v1717166302/Group_11202_fhjeo0.png"
        />
        <meta
          property="og:url"
          content="https://www.lesmarchés.com/avis-d-attributions"
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <AppContainer
          style={{
            backgroundColor: "#EFF5F3",
          }}
        >
          <HeaderApp />
          <BodyComponent>
            <CustomHeaderTitle title="AVIS D'ATTRIBUTION" />
            {isLoading ? (
              <div
                className="in-container-loader"
                style={{ marginBottom: "100px" }}
              >
                <div className="spinner-loader"></div>
              </div>
            ) : (
              <>
                <SectionAwareNoticePage
                  recapData={recapData}
                  defaultSelectedType={type}
                />
                <SectionNewsLetter />
                <SectionPartners padding="0px" margin="0px" width="100%" />
              </>
            )}
          </BodyComponent>
          {!isLoading && <FooterApp />}
        </AppContainer>
    </>
  );
}
