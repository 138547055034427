import React from "react";
import {
  ContactHeaderMessage,
  ContactInfosHeader,
  ContainerContactInfo,
  ContainerContactInfoBody,
  ContainerContactInfoData,
} from "../styles/cards";
import { FaPhone } from "react-icons/fa6";
import { IoIosMail } from "react-icons/io";
import { FaMapMarkerAlt } from "react-icons/fa";
import { BsMailbox2Flag } from "react-icons/bs";

export default function HeaderContact() {
  return (
    <ContactInfosHeader>
      <ContactHeaderMessage style={{ fontSize: "13pt"}}>
        <div style={{
          // border: "2px solid #258968",
          // borderRadius: "50px",
          // padding: "5px"
        }}>
          <marquee>Rendre les marchés publics & privés accessibles à tous ...</marquee>
          {/* <p className="cursor typewriter-animation">Rendre les marchés publics & privés accessibles à tous ...</p> */}
        </div>
      </ContactHeaderMessage>
      <div style={{ display: "flex", justifyContent: "flex-end"}}>
        <ContainerContactInfo
          color="#FFF"
          style={{
            marginRight: "10px",
            marginTop: "2px",
            borderRight: "1px dashed black",
          }}
        >
          <ContainerContactInfoBody className="animate__animated animate__zoomIn">
            <div>
              <FaPhone
                color="#2EAB82"
                size={24}
                style={{ marginRight: "5px" }}
              />
            </div>
            <ContainerContactInfoData>
              <div style={{ fontSize: "11pt" }}>
                <small style={{ padding: "5px" }}>Téléphone</small> <br />
                <a
                  href="tel: +221771646767"
                  className="btn-shine"
                  style={{ color: "white" }}
                >
                  +221 77 164 67 67
                </a>
              </div>
            </ContainerContactInfoData>
          </ContainerContactInfoBody>
        </ContainerContactInfo>
        <ContainerContactInfo
          color="#FFF"
          style={{
            marginRight: "5px",
            marginTop: "2px",
            borderRight: "1px dashed black",
          }}
        >
          <ContainerContactInfoBody className="animate__animated animate__zoomIn">
            <div>
              <IoIosMail
                color="#FFCC00"
                size={24}
                style={{ marginRight: "5px" }}
              />
            </div>
            <ContainerContactInfoData>
              <div style={{ fontSize: "11pt" }}>
                <small style={{ padding: "5px" }}>contact@lesmarchés.com</small>{" "}
                <br />
                <a href="mailto: contact@lesmarchés.com" className="btn-shine">
                  Contactez-nous
                </a>
              </div>
            </ContainerContactInfoData>
          </ContainerContactInfoBody>
        </ContainerContactInfo>
        <ContainerContactInfo
          color="#FFF"
          style={{
            marginRight: "5px",
            marginTop: "2px",
            borderRight: "1px dashed black",
          }}
        >
          <ContainerContactInfoBody className="animate__animated animate__zoomIn">
            <div>
              <FaMapMarkerAlt
                color="#FF5900"
                size={24}
                style={{ marginRight: "5px" }}
              />
            </div>
            <ContainerContactInfoData>
              <div style={{ fontSize: "11pt" }}>
                <small style={{ padding: "5px" }}>97, Sacré-Coeur 3</small>{" "}
                <br />
                <a
                  href="https://www.google.com/maps/search/97,+Sacr%C3%A9-+Coeur+3,+Dakar/@14.7242989,-17.4861021,19.96z?authuser=0&entry=ttu"
                  className="btn-shine"
                  target="_blank"
                  rel="noreferrer"
                >
                  DAKAR
                </a>
              </div>
            </ContainerContactInfoData>
          </ContainerContactInfoBody>
        </ContainerContactInfo>
        <ContainerContactInfo
          color="#FFF"
          style={{
            marginRight: "5px",
            marginTop: "2px",
            // borderRight: "1px dashed black",
          }}
        >
          <ContainerContactInfoBody className="animate__animated animate__zoomIn">
            <div>
              <BsMailbox2Flag
                color="#1D1D35"
                size={24}
                style={{ marginRight: "5px" }}
              />
            </div>
            <ContainerContactInfoData>
              <div style={{ fontSize: "11pt" }}>
                <small style={{ padding: "5px" }}>BP: 15948, Fann-DAKAR</small>{" "}
                <br />
                <a
                  href="https://www.google.com/maps/search/975,+Sacr%C3%A9-+Coeur+3,+Dakar/@14.7242989,-17.4861021,19.96z?authuser=0&entry=ttu"
                  className="btn-shine"
                  target="_blank"
                  rel="noreferrer"
                >
                  SENEGAL
                </a>
              </div>
            </ContainerContactInfoData>
          </ContainerContactInfoBody>
        </ContainerContactInfo>
      </div>
    </ContactInfosHeader>
  );
}
