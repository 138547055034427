import React, { useEffect, useState } from "react";
import {
  ModalMenuContainer,
  ModalMenuItem,
  ModalMenuLogoContainer,
} from "../../styles/containers";
import { AppLogo } from "../../styles/home";
import { EAtrributionType, isMobileOrSmallScreen } from "../../utils";
import logo from "../../assets/logo.svg";
import { Link } from "react-router-dom";
import { FaSortDown } from "react-icons/fa6";

export default function ModalMenu({
  isOpen = false,
  backgroundColor = "#2EAB82",
  onClose,
}) {
  const [activeTab, setActiveTab] = useState(window.location.pathname || "");

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add("modal-open");
    } else {
      document.body.classList.remove("modal-open");
    }
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    <div id="myModal" className="modal">
      <div
        className="modal-content"
        style={{
          margin: `0px`,
          backgroundColor,
          borderColor: "none",
          borderRadius: "0px",
          width: "90%",
          border: "none",
        }}
      >
        <ModalMenuContainer>
          <ModalMenuLogoContainer>
            <AppLogo
              src={logo}
              style={{
                height: isMobileOrSmallScreen() ? "10vw" : "3.7vw",
              }}
            />
          </ModalMenuLogoContainer>
          <div>
            <Link to="/">
              <ModalMenuItem
                style={{
                  ...(activeTab === "/" && {
                    backgroundColor: "rgba(255, 204, 0, 0.1)",
                    borderBottom: "2px solid #FFCC00",
                  }),
                }}
              >
                ACCUEIL
              </ModalMenuItem>
            </Link>
            <Link to="/news">
              <ModalMenuItem
                style={{
                  ...(activeTab.includes("/news") && {
                    backgroundColor: "rgba(255, 204, 0, 0.1)",
                    borderBottom: "2px solid #FFCC00",
                  }),
                }}
              >
                ACTUALITÉS
              </ModalMenuItem>
            </Link>
            <div className="dropdown" style={{ width: "100%" }}>
              <ModalMenuItem
                style={{
                  ...((activeTab.includes("/plan-de-march") ||
                    activeTab.includes("/appels-d-offres") ||
                    activeTab.includes("/avis-d-attributions")) && {
                    backgroundColor: "rgba(255, 204, 0, 0.1)",
                    borderBottom: "2px solid #FFCC00",
                  }),
                }}
              >
                LES MARCHÉS <FaSortDown style={{ verticalAlign: "text-top" }} />
              </ModalMenuItem>
              <div
                className="dropdown-content"
                style={{
                  left: "50px",
                  width: "-webkit-fill-available",
                  top: "49px",
                }}
              >
                <Link to="/plan-de-marchés">
                  <small href="#">PLAN DES MARCHÉS</small>
                </Link>
                <Link to="/appels-d-offres">
                  <small href="#">AVIS D'APPELS D'OFFRES</small>
                </Link>
                <Link
                to={`/avis-d-attributions`}
              >
                <small href="#">AVIS D'ATTRIBUTION DES MARCHÉS</small>
              </Link>
                {/* <Link
                  to={`/avis-d-attributions/${EAtrributionType.PROVISIONAL.toLocaleLowerCase()}`}
                >
                  <small href="#">AVIS D'ATTRIBUTION PROVISOIRE</small>
                </Link>
                <Link
                  to={`/avis-d-attributions/${EAtrributionType.DEFINITIVE.toLocaleLowerCase()}`}
                >
                  <small href="#">AVIS D'ATTRIBUTION DÉFINITIVE</small>
                </Link> */}
              </div>
            </div>
            <Link to="/financement">
              <ModalMenuItem
                style={{
                  ...(activeTab.includes("/financement") && {
                    backgroundColor: "rgba(255, 204, 0, 0.1)",
                    borderBottom: "2px solid #FFCC00",
                  }),
                }}
              >
                FINANCEMENT
              </ModalMenuItem>
            </Link>
            <div className="dropdown" style={{ width: "100%" }}>
              <ModalMenuItem
                style={{
                  ...(["/assistance", "/formation", "/audit"].includes(
                    activeTab
                  ) && {
                    backgroundColor: "rgba(255, 204, 0, 0.1)",
                    borderBottom: "2px solid #FFCC00",
                  }),
                }}
              >
                NOS SERVICES <FaSortDown style={{ verticalAlign: "text-top" }} />
              </ModalMenuItem>
              <div
                className="dropdown-content"
                style={{
                  left: "50px",
                  width: "-webkit-fill-available",
                  top: "49px",
                }}
              >
                <Link to="/assistance">
                <small href="#">ASSISTANCE</small>
              </Link>
              <Link to="/formation">
                <small href="#">FORMATION</small>
              </Link>
              <Link to="/audit">
                <small>AUDIT</small>
              </Link>
              </div>
            </div>
            {/* <Link to="/opportunities">
              <ModalMenuItem
                style={{
                  ...(activeTab.includes("/opportunities") && {
                    backgroundColor: "rgba(255, 204, 0, 0.1)",
                    borderBottom: "2px solid #FFCC00",
                  }),
                }}
              >
                OPPORTUNITÉS D'AFFAIRES
              </ModalMenuItem>
            </Link> */}
          </div>
        </ModalMenuContainer>
      </div>
    </div>
  );
}
