import React, { useEffect, useState } from "react";
import { NewsLetterContent, NewsLetterItem } from "../styles/containers";
import newsletter from "../assets/newsletter__.svg";
import AnimatedText from "./extras/animated-text";
import { RegisterToNewsLetter } from "../configuration/api";
import CardPub from "./cards/card-pub";
import { WindowsSizeBreakpoints, isMobileOrSmallScreen } from "../utils";
import { useWindowSize } from "../utils/useWindowSize";
import { MdDoneAll } from "react-icons/md";

export default function NewsLetter({ pubsToDisplay }) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const [isRegistered, setIsRegistered] = useState(false);
  const { width } = useWindowSize();

  const registerToNewsLetter = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(email)) {
        setIsLoading(true);
        const payload = {
          email,
          isForNewsletter: true,
        };
        const response = await RegisterToNewsLetter(payload);
        const { success } = response.data;
        if (success) {
          setIsRegistered(true);
          var x = document.getElementById("snackbar");
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
          }, 3000);
        }

        setIsLoading(false);
      } else {
        alert("Merci de vérifier que l'email fourni est correct!");
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };
  return (
    <NewsLetterContent>
      <NewsLetterItem
        style={{ margin: "0px 10px", overflowWrap: "break-word" }}
      >
        <img
          src={newsletter}
          style={{ width: "15%", marginTop: "15px" }}
          alt="newsletter"
        />
        <h4
          style={{
            fontWeight: "bold",
            color: "#FFCC00",
            marginBlockEnd: "0rem",
          }}
        >
          Abonnez-vous à notre Newsletter <br />
          <p style={{ fontWeight: "bold", color: "#FFF", fontSize: "13pt" }}>
            Recevez instantanément les informations des marchés publics et privés dans votre
            courriel.{" "}
          </p>
        </h4>
      </NewsLetterItem>
      <NewsLetterItem
        style={{
          width: "-webkit-fill-available",
          ...(isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? {
                margin: "0px 10px",
              }
            : {
                margin: "0px 14vh",
              }),
        }}
      >
        <h3
          className="animate__animated animate__backInDown"
          style={{ color: "#fff" }}
        >
          Lesmarchés.com
        </h3>
        {isLoading ? (
          <div className="in-container-loader">
            <div className="spinner-loader"></div>
          </div>
        ) : (
          <>
            {isRegistered ? (
              <div className="newsletter-registered">
                Abonnement réussi <MdDoneAll color="#000" />
              </div>
            ) : (
              <form className="search-wrapper cf">
                <input
                  type="email"
                  placeholder="Entrer votre email..."
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  style={{
                    boxShadow: "none",
                    backgroundColor: "#FFF",
                  }}
                />
                <div className="button" onClick={() => registerToNewsLetter()}>
                  S'inscrire
                </div>
              </form>
            )}
          </>
        )}
      </NewsLetterItem>
      <NewsLetterItem
        style={{
          margin: "0px 10px",
          overflowWrap: "break-word",
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        {pubsToDisplay?.map((pub, index) => (
          <img
            src={pub?.image}
            style={{
              ...(isMobileDevice ||
              Boolean(width <= WindowsSizeBreakpoints.medium)
                ? {
                    width: "50%",
                  }
                : {
                    width: "30%",
                  }),
              margin: "5px",
              marginTop: "18px",
            }}
            alt={pub?.name || "publicité"}
            key={index}
          />
        ))}
      </NewsLetterItem>
      <div id="snackbar">
        Merci ! Votre inscription a été bien prise en compte{" "}
        <MdDoneAll color="#000" />
      </div>
    </NewsLetterContent>
  );
}
