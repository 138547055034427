import React, { useState } from "react";
import {
  ContentNewsLetter,
  ContentSideNewsLetter,
  SectionFullContainer,
  SectionTitle,
  SubscribersCircle,
} from "../styles/containers";
import { RegisterToNewsLetter } from "../configuration/api";
import { WindowsSizeBreakpoints, isMobileOrSmallScreen } from "../utils";
import { useWindowSize } from "../utils/useWindowSize";
import { MdDoneAll } from "react-icons/md";

export default function SectionNewsLetter({ subscribers = 0 }) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [isMobileDevice, _] = useState(isMobileOrSmallScreen());
  const [isRegistered, setIsRegistered] = useState(false);
  const { width } = useWindowSize();

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      registerToNewsLetter();
    }
  };

  const registerToNewsLetter = async () => {
    try {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (emailRegex.test(email)) {
        setIsLoading(true);
        const payload = {
          email,
          isForNewsletter: true,
        };
        const response = await RegisterToNewsLetter(payload);
        const { success } = response.data;
        if (success) {
          setEmail("");
          setIsRegistered(true);
          var x = document.getElementById("snackbar");
          x.className = "show";
          setTimeout(function () {
            x.className = x.className.replace("show", "");
          }, 3000);
        }
        setIsLoading(false);
      } else {
        alert("Merci de vérifier que l'email fourni est correct!");
      }
    } catch (error) {
      setIsLoading(false);
      console.log({ error });
    }
  };

  return (
    <SectionFullContainer style={{ backgroundColor: "white" }}>
      <ContentNewsLetter
        style={{
          ...(isMobileDevice || Boolean(width <= WindowsSizeBreakpoints.medium)
            ? {
                marginTop: "50px",
                flexDirection: "column",
              }
            : {
                flexDirection: "row",
              }),
        }}
      >
        <ContentSideNewsLetter>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SubscribersCircle>
              <b> + {(10000 + subscribers).toLocaleString("fr-FR")} Abonnés</b>
            </SubscribersCircle>
          </div>
        </ContentSideNewsLetter>
        <ContentSideNewsLetter>
          <SectionTitle>
            <h3
              style={{
                marginTop: "0.2em",
                marginBottom: "0.2em",
                color: "black",
              }}
            >
              Newsletter
            </h3>
          </SectionTitle>
          {isLoading ? (
            <div className="in-container-loader">
              <div className="spinner-loader"></div>
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <small style={{ fontSize: "10pt", textAlign: "center" }}>
                  {/* Abonnez-vous à notre newsletter et restez informé de
                  l'actualité des marchés */}
                  Recevez instantanément les informations des marchés publics et privés dans votre
                  courriel.
                </small>
              </div>
              {isRegistered ? (
                <div className="newsletter-registered">
                  Abonnement réussi <MdDoneAll color="#000" />
                </div>
              ) : (
                <form className="search-wrapper cf">
                  <input
                    type="email"
                    placeholder="Entrer votre email..."
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    style={{
                      boxShadow: "none",
                      backgroundColor: "rgba(46, 171, 130, 0.1)",
                    }}
                    onKeyPress={handleKeyPress}
                  />
                  <div
                    className="button"
                    onClick={() => registerToNewsLetter()}
                  >
                    S'inscrire
                  </div>
                </form>
              )}
            </>
          )}
        </ContentSideNewsLetter>
      </ContentNewsLetter>
      <div id="snackbar">
        Merci ! Votre inscription a été bien prise en compte{" "}
        <MdDoneAll color="#000" />
      </div>
    </SectionFullContainer>
  );
}
